import React, { useState } from 'react'
import './staking.sass'
import Popup from 'reactjs-popup'
import getUsdcBalance from '../../components/ultities/getUsdcBalance'
import { useWallet } from '@solana/wallet-adapter-react'
import Wallet from '../../components/wallet/wallet'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import '../../styles/progress-bar.css'

const Staking = () => {
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)
  const [selectDuration, setSelectDuration] = useState(88)
  const [balance, setBalance] = useState()
  const [amount, setAmount] = useState()
  const wallet = useWallet()

  const duration = [
    { apy: '10%', months: '1 month', value: 10 },
    { apy: '15%', months: '2 month', value: 15 },
    { apy: '20%', months: '3 month', value: 20 },
    { apy: '25%', months: '4 month', value: 25 },
    { apy: '30%', months: '5 month', value: 30 },
    { apy: '35%', months: '6 month', value: 35 },
    { apy: '40%', months: '7 month', value: 40 },
    { apy: '45%', months: '8 month', value: 45 },
    { apy: '50%', months: '9 month', value: 50 },
    { apy: '55%', months: '10 month', value: 55 },
    { apy: '60%', months: '11 month', value: 60 },
    { apy: '88%', months: '12 month', value: 88 }
  ]
  const myStakes = [
    { amount: 1000, date: '2024.10.12', duration: 2, apy: 42, claim: true },
    { amount: 1000, date: '2024.10.15', duration: 2, apy: 42, claim: true }
  ]

  const handleWallet = async () => {
    console.log('Trying to connect')
    try {
      await wallet.connect()
    } catch (error) {
      setOpen(o => !o)
      console.error('Error connecting wallet:', error)
      // Handle error
    }
  }

  const handleSelectApy = (v) => {
    setSelectDuration(v)
    console.log('Got: ', v)
  }

  const getTokenBalance = async () => {
    if (!wallet.publicKey) {
      handleWallet()
    } else {
      const balance = await getUsdcBalance(wallet.publicKey)
      setBalance(balance)
    }
  }

  return (
  <div id="staking" className="stakingSection">
    <div className="stakingInnerWrap">
      <div className="stakingRow">
        <h2>Staking<span className='comingSoon'>Coming soon</span></h2>
        <div className="stakingBlocksContainer">
          <div className="stakingInner">
            <span>XXX,XXX</span>
            <span className='noteText'>Total $GODLEN staked</span>
          </div>
          <div className="stakingInner">
            <span>XXX%</span>
            <span className='noteText'>AVG. staking period</span>
          </div>
          <div style={{ width: '40%' }} className="stakingInner">
            <span>XXX,XXX $GODLEN</span>
            <span className='noteText'>Total amount stakers eraned</span>
          </div>
        </div>
      </div>
    </div>
    <div className="stakingInnerWrap">
      <div className="stakingRow">
        <div className="stakingBlocksContainer2">
          <h3>1. Select duration</h3>
          {duration.map((d, index) => (
            <button className={d.value === selectDuration ? 'active stakingInner2' : 'stakingInner2'} onClick={() => { handleSelectApy(d.value) }} key={index}>
              <span>{d.apy} APY</span>
              <span className='noteText'>{d.months}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
    <div className="stakingOuterWrap">
      <div className='stakingInnerWrap2'>
       <h3>2. Enter amount to stake</h3>
        <div className="stakingInput">
          <div className='stakingInputWrap'>
            <input type='text' placeholder='Enter amount' value={amount} />
            <button>Max</button>
          </div>
          <p>Your balance: <span>{balance || 'xxxx'} $GODLEN</span></p>
        </div>
         <button onClick={() => { getTokenBalance(wallet.publicKey) }} className='buttonStake'>{!wallet.publicKey ? 'Connect wallet to stake' : 'Staking coming soon'}</button>
         <div className='stakingInnerWrap3'>
          <p>Don't have $GODLEN?</p>
          <Link href='/referral'>Buy and stake now to earn rewards</Link>
         </div>
      </div>
      <div className='stakingInnerWrap4'>
        <h3>Staking pool (FCFS)</h3>
        <div className='stakingPool'>
          <div className='stakingPoolWrap'>
            <p>Staking pool includes 10% from total $GODLEN supply,  which is 78.7M </p>
            <StaticImage
              style={{ transform: 'scaleX(-1)', marginLeft: -30 }}
              src="../../images/godlenfish-coat.png"
              width={240}
              placeholder="blurred"
              layout="fixed"
              alt="The Godlenfish logo"
            />
          </div>
          <div className='stakingPoolWrapCircle'>
          <CircularProgressbarWithChildren value={0} strokeWidth={10}>
            <div className='innerCircleText' style={{ fontSize: 24, marginTop: -5 }}>
              <strong>0%</strong>
              <span>Pool filled</span>
            </div>
          </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
    </div>
    <div className="stakingOuterWrap">
      <div className='stakingInnerWrap4'>
        <h3>Extra rewards for pre-sale participants</h3>
        <div className='stakingWrapIt'>
          <div className="stakingInner3">
            <span>Rewards 1.5X</span>
            <p>If you bought during presale more than 1 $SOL it means your wallet address is whitelisted for this reward! Staking from this address within first 30 days after launch will give you 1.5x staking APY more. </p>
          </div>
          <div className="stakingInner3">
            <span>Rewards 2X</span>
            <p>If you bought during presale for 20 $SOL it means, you are the holder of G exclusive NFT and your wallet address is whitelisted for this reward! Staking from this address within first 30 days after launch will give you 2x staking APY more.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="stakingOuterWrap">
      <div className='stakingInnerWrap4'>
        <h3>My stakes</h3>
          <div className="myStakesContainer">
            <ul className='myStakesInnerWrap'>
              <li className='myStakesHeader'>
                <span>Staking amount</span>
                <span>Staking date / Duration</span>
                <span>APY</span>
                <span className='lastMiddle'>Claim rewards</span>
              </li>
              {/* {myStakes.map((stake, i) => (
                <li key={i} className='myStakesBody'>
                  <span>{stake.amount} $GODLEN</span>
                  <span>{stake.date} / {stake.duration}m</span>
                  <span>{stake.apy}%</span>
                  <button >Claim now</button>
                </li>
              ))} */}
              <span>You have no stakes yet</span>
            </ul>
          </div>
      </div>
    </div>
    <Popup className="modalWrapper" open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <a className="close" onClick={closeModal}>
            &times;
          </a>
          <Wallet closeModal={closeModal} />
        </div>
      </Popup>
  </div>
  )
}

export default Staking
