// getSolBalance.js

import { Connection, PublicKey } from '@solana/web3.js'
import * as splToken from '@solana/spl-token'

const getUsdcBalance = async (accountPublicKey) => {
  const tokenMintAddress = 'FishgChr4rMPf1BgnZgHN6hkP443uGTe1ZpMmMJ7gS2B'
  const mintPublicKey = new PublicKey(tokenMintAddress)
  // connection
  const connection = new Connection('https://fittest-little-energy.solana-mainnet.quiknode.pro/cc1ab8c87fcd8ac6fca8bf440ceba311131fa5a9/')
  if (!accountPublicKey) {
    window.scrollTo(0, 0)
    return alert('Solana wallet is not installed or not connected.')
  }

  try {
    console.log(accountPublicKey)
    const fromTokenAccount = await splToken.getOrCreateAssociatedTokenAccount(
      connection,
      accountPublicKey,
      mintPublicKey,
      accountPublicKey
    )
    const tokenAccountBalance = await connection.getTokenAccountBalance(fromTokenAccount.address)
    return tokenAccountBalance.value.amount / 1000000
  } catch (error) {
    console.error('Error getting SOL balance:', error)
    return null
  }
}

export default getUsdcBalance
